import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { getDisplayLabel } from '../../helpers/GeneralHelpers.js';

const styles = {
	formHelperText: { margin: '0 14px' }
};

const SBCheckBoxField = ({ id, label, onChange, helperText, canEdit = true, checked = false, disabled = false, ...other }) => {
	// Determine the actual disabled state
	const isDisabled = disabled || !canEdit;
	const displayLabel = getDisplayLabel(label, id);

	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						id={id}
						size="small"
						checked={checked}
						onChange={(event) => onChange(event.target.checked, id)}
						disabled={isDisabled}
						{...other}
					/>
				}
				label={displayLabel}
			/>
			{helperText &&
				<FormHelperText style={styles.formHelperText}>
					{helperText}
				</FormHelperText>
			}
		</>
	);
};

SBCheckBoxField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	canEdit: PropTypes.bool,
	disabled: PropTypes.bool,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SBCheckBoxField;