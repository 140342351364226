import React from 'react';
import apiRequestManager from '../api/Services/ApiRequestManager';
import * as Api from '../api/AdminApi';
import { hasDataLoaded } from '../helpers/GeneralHelpers';
import * as SiteDataAdapter from '../providers/DataAdapters/SiteLocationDataAdapters';
import * as LocationDBHelper from '../helpers/Database/SiteLocationDBHelpers';
import { convertArrayToSelectList } from '../helpers/GeneralHelpers';
import { IMAGE_TYPE } from '../constants/FileTypeConstants';

export const SiteDataContext = React.createContext(null);

export class SiteDataProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentSiteData: {},
			siteBrandingImgSrc: "",
			uniclassVersions: [],
			linkTypes: [],
			fileDetailLevels: {},
			flatFileDetailLevels: [],
			structuralNames: [],
			siteLocations: [],
			facilityList: [],
			systemMaintenanceVersions: [],
			taskVersions: [],
			RIBAWorkStages: [],
			siteClassificationTemplates: [],
			siteClassifications: [],
			mySitesData: [],
		};
	}

	loadCurrentSite = async () => {
		if (hasDataLoaded(this.state.currentSiteData))
			return this.state.currentSiteData;
		try {
			const currentSiteData = await Api.getCurrentSiteData();
			this.setState({ currentSiteData, siteBrandingImgSrc: currentSiteData.siteBranding });
			return currentSiteData;
		} catch (error) {
			console.error("Error loading current site data:", error);
		}
	}

	addSiteLocation = async (parentId, locationName) => {
		let addReq = {
			parentId: parentId,
			name: locationName
		};
		try {
			const siteLocations = await Api.addSiteLocation(addReq);
			console.log("Adding SITE Location:", siteLocations);
			this.setState({ siteLocations })
			return siteLocations;
		} catch (error) {
			console.error("Error adding site location:", error);
		}
	}

	moveSiteLocation = async (sourceNodeId, destinationNodeId) => {
		let moveReq = {
			sourceNodeId: sourceNodeId,
			destinationNodeId: destinationNodeId,
			type: "last"
		};
		try {
			const siteLocations = await Api.moveLocation(moveReq);
			console.log("Adding SITE Location:", siteLocations);
			this.setState({ siteLocations })
			return siteLocations;
		} catch (error) {
			console.error("Error adding site location:", error);
		}
	}

	deleteLocationBranch = async (locationId) => {
		let deleteReq = {
			id: locationId
		};
		try {
			const siteLocations = await Api.deleteBranch(deleteReq);
			console.log("deleting SITE Location:", siteLocations);
			this.setState({ siteLocations })
			return siteLocations;
		} catch (error) {
			console.error("Error adding site location:", error);
		}
	}

	updateSite = async (siteData) => {
		try {
			const currentSiteData = await Api.updateSite(siteData);
			//console.log("UPDATE SITE:", currentSiteData);
			this.setState({ currentSiteData });
			return currentSiteData;
		} catch (error) {
			console.error("Error updating site data:", error);
		}
	}

	loadUniclassVersions = async () => {
		if (hasDataLoaded(this.state.uniclassVersions))
			return this.state.uniclassVersions;
		try {
			const uniclassVersions = await Api.getUniclassVersions();
			this.setState({ uniclassVersions: convertArrayToSelectList(uniclassVersions) });
			return uniclassVersions;
		} catch (error) {
			console.error("Error loading uniclass versions:", error);
		}
	}

	loadRIBAStages = async () => {
		if (hasDataLoaded(this.state.RIBAWorkStages))
			return this.state.RIBAWorkStages;
		const RIBAWorkStages = [
			{ id: 0, name: "0 Strategic Definition" },
			{ id: 1, name: "1 Preparation & Brief" },
			{ id: 2, name: "2 Concept Design" },
			{ id: 3, name: "3 Developed Design" },
			{ id: 4, name: "4 Technical Design" },
			{ id: 5, name: "5 Construction" },
			{ id: 6, name: "6 Handover and Close Out" },
			{ id: 7, name: "7 In Use" }
		];
		this.setState({ RIBAWorkStages });
		return RIBAWorkStages;
	}

	loadTaskVersions = async () => {
		if (hasDataLoaded(this.state.taskVersions))
			return this.state.taskVersions;
		const taskVersions = [
			{ id: 0, name: "Default (V1)" },
			{ id: 1, name: "System (V2)" }
		];
		this.setState({ taskVersions });
		return taskVersions;
	}

	loadSystemMaintenanceVersions = async () => {
		if (hasDataLoaded(this.state.systemMaintenanceVersions))
			return this.state.systemMaintenanceVersions;
		const systemMaintenanceVersions = [
			{ id: 0, name: "Default" },
			{ id: 1, name: "Task App" },
			{ id: 2, name: "Scoped Actions" }
		];
		this.setState({ systemMaintenanceVersions });
		return systemMaintenanceVersions;
	}

	loadFileDetailLevels = async () => {
		if (hasDataLoaded(this.state.fileDetailLevels))
			return this.state.fileDetailLevels;
		const fileDetailLevels = {
			0: [],
			1: ["author", "linkType"],
			2: ["revision", "status"],
			3: ["issue"]
		};

		// Create a 'flat' version of the file details levels
		const flatFileDetailLevels = [];
		let accumulatedLabels = [];

		for (const key in fileDetailLevels) {
			accumulatedLabels = [...accumulatedLabels, ...fileDetailLevels[key]];
			const label = accumulatedLabels.join(", ");
			flatFileDetailLevels.push({
				id: parseInt(key, 10),
				name: label || "None" // Use "None" if the label is empty
			});
		}

		this.setState({ fileDetailLevels, flatFileDetailLevels });
		return fileDetailLevels;
	}


	loadLinkTypes = async () => {
		if (hasDataLoaded(this.state.linkTypes))
			return this.state.linkTypes;
		try {
			console.log("linkTypes load");
			const linkTypes = await Api.loadLinkTypes();
			this.setState({ linkTypes });
			return linkTypes;
		} catch (error) {
			console.error("Error loading linkTypes:", error);
		}
	}

	loadStructuralNames = async () => {
		if (hasDataLoaded(this.state.structuralNames))
			return this.state.structuralNames;
		try {
			console.log("structuralNames load");
			const structuralNames = await Api.loadStructuralNames();
			this.setState({ structuralNames });
			return structuralNames;
		} catch (error) {
			console.error("Error loading structuralNames:", error);
		}
	}

	loadSiteLocations = async () => {
		if (hasDataLoaded(this.state.siteLocations))
			return this.state.siteLocations;
		try {
			console.log("site locations load");
			const siteLocations = await Api.loadSiteLocations();
			const facilities = LocationDBHelper.getFacilitiesList(siteLocations);
			this.setState({ facilityList: facilities, siteLocations });
			return siteLocations;
		} catch (error) {
			console.error("Error loading site locations:", error);
		}
	}

	updateById = (data, id, newSiteLocation) => {
		for (const item of data) {
			if (item.id === id) {
				Object.assign(item, newSiteLocation);
				return true;
			}
			if (item.locations && item.locations.length > 0) {
				const updated = this.updateById(item.locations, id, newSiteLocation);
				if (updated) {
					return true;
				}
			}
		}
		return false;
	}

	updateSiteLocation = async (siteLocation) => {
		const { siteLocations } = this.state;
		const updatedSiteLocations = [...siteLocations];

		const updateSuccess = this.updateById(updatedSiteLocations, siteLocation.id, siteLocation);

		if (updateSuccess) {
			this.setState({ siteLocations: updatedSiteLocations });
		}
	}

	updateSiteLocationData = async (siteLocation) => {
		let siteLocationReq = SiteDataAdapter.convertSiteLocationDataToServerFormat(siteLocation);

		try {
			let siteLocations = await Api.updateSiteLocation(siteLocationReq);
			let facilities = LocationDBHelper.getFacilitiesList(siteLocations);

			this.setState({ facilityList: facilities, siteLocations: siteLocations });
			return siteLocations;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	updateSiteLocationImageMapPoints = async (imageMap) => {
		let imageMapReq = {
			LocationImageMapId: imageMap.id,
			points: imageMap.points
		};
		let requestId = apiRequestManager.addRequestToQueue("updateSiteLocationImageMapPoints");
		try {
			let response = await Api.updateImageMapPoints(imageMapReq);
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated image map points!");
			return response;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	createSiteLocationQuickLink = async (quickLink) => {
		try {
			// This currently returns an array???
			let response = await Api.createQuickLink(quickLink);
			console.log("Quick link response: ", response);
			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.createQuickLink(prevState.siteLocations, quickLink.siteLocationId, quickLink);
				return { siteLocations: updatedSiteLocations };
			});
			return response;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	updateSiteLocationQuickLink = async (quickLink) => {
		try {
			let response = await Api.updateQuickLink(quickLink);
			console.log("update Quick link response: ", response);

			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.updateQuickLinks(prevState.siteLocations, quickLink.siteLocationId, quickLink);
				return { siteLocations: updatedSiteLocations };
			});

			return response;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	deleteSiteLocationQuickLink = async (quickLinkToRemove) => {
		try {
			let response = await Api.deleteQuickLink(quickLinkToRemove);
			console.log("delete Quick link response: ", response);

			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.deleteQuickLink(prevState.siteLocations, quickLinkToRemove.siteLocationId, quickLinkToRemove.id);
				return { siteLocations: updatedSiteLocations };
			});

			return response;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	processSiteImage = async (imageData) => {
		try {
			let newImageData = await Api.processSiteImage(imageData);

			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.addSiteImage(prevState.siteLocations, newImageData.siteLocationId, newImageData);
				return { siteLocations: updatedSiteLocations };
			});

			return newImageData;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	processImageMapImage = async (imageData) => {
		try {
			let newImageData = await Api.processImageMapImage(imageData);
			return newImageData;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	processSiteBrandingImage = async (imageData) => {
		try {
			let newImageData = await Api.processSiteBrandingImage(imageData);
			console.log("SITE BRANDING:", newImageData);
			return newImageData;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	deleteSiteImages = async (imageIds, siteLocationId) => {
		let deleteReq = imageIds.map(imageId => ({
			id: imageId,
			imageType: IMAGE_TYPE.Siteimages,
			siteLocationId: siteLocationId
		}));

		try {
			let deleteResults = await Api.deleteSiteImages(deleteReq);

			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.deleteSiteImages(prevState.siteLocations, siteLocationId, imageIds);
				return { siteLocations: updatedSiteLocations };
			});

			return deleteResults;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	deleteImageMapImage = async (imageId, siteLocationId) => {
		let deleteReq = {
			id: imageId,
			imageType: IMAGE_TYPE.LocationImageMap,
			siteLocationId: siteLocationId
		};
		try {
			let deleteResults = await Api.deleteImageMapImage(deleteReq);

			this.setState((prevState) => {
				const updatedSiteLocations = LocationDBHelper.deleteImageMapImage(prevState.siteLocations, siteLocationId);
				return { siteLocations: updatedSiteLocations };
			});

			return deleteResults;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	searchLatLong = async (searchParams) => {
		try {
			let searchResults = await Api.searchLatlong(searchParams);

			console.log("Search results:", searchResults);

			return searchResults;
		} catch (error) {
			console.error("ERROR during lat long search:", error);
		}
	}

	loadAllClassifications = async () => {
		if (hasDataLoaded(this.state.siteClassifications))
			return this.state.siteClassifications;
		try {
			console.log("siteClassifications load context!");
			const siteClassifications = await Api.loadAllClassifications();
			this.setState({ siteClassifications });
			return siteClassifications;
		} catch (error) {
			console.error("Error loading site classifications:", error);
		}
	}

	loadAllClassificationTemplates = async () => {
		if (hasDataLoaded(this.state.siteClassificationTemplates))
			return this.state.siteClassificationTemplates;
		try {
			console.log("site classification templates load");
			const siteClassificationTemplates = await Api.loadAllClassificationTemplates();
			this.setState({ siteClassificationTemplates });
			return siteClassificationTemplates;
		} catch (error) {
			console.error("Error loading site classification templates:", error);
		}
	}

	applyProjectClassificationConfig = async (templateData) => {
		let requestId = apiRequestManager.addRequestToQueue("applyProjectClassificationConfig");
		try {
			let response = await Api.applyProjectClassificationConfig(templateData);
			apiRequestManager.setRequestSuccess(requestId, "Successfully applied project classification config template!");
			return response;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	saveProjectConfigAsTemplate = async (templateData) => {
		let requestId = apiRequestManager.addRequestToQueue("saveProjectConfigAsTemplate");
		try {
			let configTemplates = await Api.saveProjectConfigAsTemplate(templateData);
			apiRequestManager.setRequestSuccess(requestId, "Successfully saved project classification config template!");
			// Add new config templates to cached list
			this.setState((prevState) => ({
				siteClassificationTemplates: [
					...prevState.siteClassificationTemplates,
					...configTemplates
				]
			}));
			return configTemplates;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	loadMySitesData = async () => {
		if (hasDataLoaded(this.state.mySitesData))
			return this.state.mySitesData;
		try {
			console.log("Loading my sites data");
			const sitesData = await Api.getMySites();
			sitesData.sort((a, b) => a.name.localeCompare(b.name));
			this.setState({ mySitesData: sitesData });
			return sitesData;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	render() {
		const siteDataContextValue = {
			currentSiteData: this.state.currentSiteData,
			siteBrandingImgSrc: this.state.siteBrandingImgSrc,
			uniclassVersions: this.state.uniclassVersions,
			linkTypes: this.state.linkTypes,
			fileDetailLevels: this.state.fileDetailLevels,
			flatFileDetailLevels: this.state.flatFileDetailLevels,
			structuralNames: this.state.structuralNames,
			siteLocations: this.state.siteLocations,
			facilityList: this.state.facilityList,
			systemMaintenanceVersions: this.state.systemMaintenanceVersions,
			taskVersions: this.state.taskVersions,
			RIBAWorkStages: this.state.RIBAWorkStages,
			siteClassificationTemplates: this.state.siteClassificationTemplates,
			siteClassifications: this.state.siteClassifications,
			mySitesData: this.state.mySitesData,
			loadRIBAStages: this.loadRIBAStages,
			loadLinkTypes: this.loadLinkTypes,
			loadStructuralNames: this.loadStructuralNames,
			loadTaskVersions: this.loadTaskVersions,
			loadSystemMaintenanceVersions: this.loadSystemMaintenanceVersions,
			loadFileDetailLevels: this.loadFileDetailLevels,
			loadAllClassifications: this.loadAllClassifications,
			loadAllClassificationTemplates: this.loadAllClassificationTemplates,
			applyProjectClassificationConfig: this.applyProjectClassificationConfig,
			saveProjectConfigAsTemplate: this.saveProjectConfigAsTemplate,
			loadMySitesData: this.loadMySitesData,
			// SITE LOCATIONS:
			loadSiteLocations: this.loadSiteLocations,
			addSiteLocation: this.addSiteLocation,
			moveSiteLocation: this.moveSiteLocation,
			deleteLocationBranch: this.deleteLocationBranch,
			updateSite: this.updateSite,
			loadCurrentSite: this.loadCurrentSite,
			loadUniclassVersions: this.loadUniclassVersions,
			updateSiteLocation: this.updateSiteLocation,
			updateSiteLocationData: this.updateSiteLocationData,
			updateSiteLocationImageMapPoints: this.updateSiteLocationImageMapPoints,
			createSiteLocationQuickLink: this.createSiteLocationQuickLink,
			updateSiteLocationQuickLink: this.updateSiteLocationQuickLink,
			deleteSiteLocationQuickLink: this.deleteSiteLocationQuickLink,
			processSiteImage: this.processSiteImage,
			processImageMapImage: this.processImageMapImage,
			processSiteBrandingImage: this.processSiteBrandingImage,
			deleteSiteImages: this.deleteSiteImages,
			deleteImageMapImage: this.deleteImageMapImage,
			searchLatLong: this.searchLatLong
		}

		return (
			<SiteDataContext.Provider value={siteDataContextValue}>
				{this.props.children}
			</SiteDataContext.Provider>
		);
	}
}

export default SiteDataProvider;