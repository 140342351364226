import React, { useState, useContext, useEffect } from 'react';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	FormHelperText,
	Typography,
	Stack
} from '@mui/material';
import {
	BasicTxtField,
	InputError,
	SBDatePicker,
} from '../../ui';
import { HelpContext, ProjectContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';
import { SERVER_DATE_FORMAT } from '../../../constants/Constants';

const formValidators = {
	name: [Validation.validateNotEmpty],
	plannedCompletion: [Validation.validateNotEmpty]
}

const styles = {
	formHelperText: { margin: '0 14px' }
};

const initialValue = {
	name: '',
	reference: '',
	clientReference: '',
	plannedCompletion: ''
};

const ProjectCreatePage = ({ onClose }) => {
	const [busy, setBusy] = useState(false);
	const [project, setProject] = useState({ ...initialValue	});
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	const { createProject } = useContext(ProjectContext);
	const { helpTexts, loadHelpByTitles } = useContext(HelpContext);

	useEffect(() => {
		setProject({ ...initialValue });
		resetFormErrors();
	}, [resetFormErrors]);
	// useEffect(() => {
	// 	console.log('edited project', project);
	// }, [project])

	useEffect(() => {
		setBusy(true);

		async function getApis() {
			await loadHelpByTitles(['PCDate']);
			setBusy(false);
		}

		getApis();
	}, [loadHelpByTitles])

	const handleDataChange = (propertyName, newValue) => {
		setProject(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid(project)) return;

		const projectData = await createProject(project);
		if (projectData) {
			onClose();
		}
	};

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
							<Typography variant="body1">Add project</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<Alert severity="warning">Adding a project may affect the cost of your subscription.</Alert>
						<BasicTxtField
							id={"projectName"}
							label="Project name"
							value={project.name}
							onChange={(value) => handleDataChange("name", value)}
							fullWidth={true}
							errors={formErrors['name']}
							required
						/>
						<BasicTxtField
							id={"projectReference"}
							label="Project reference"
							value={project.reference}
							onChange={(value) => handleDataChange("reference", value)}
							fullWidth={true}
						/>
						<BasicTxtField
							id={"clientReference"}
							label="Client reference"
							value={project.clientReference}
							onChange={(value) => handleDataChange("clientReference", value)}
							fullWidth={true}
						/>
						<SBDatePicker
							id="plannedCompletion"
							label="PC date *"
							helperText={helpTexts["PCDate"]}
							errors={formErrors["plannedCompletion"]}
							dateFormat={SERVER_DATE_FORMAT}
							onDateChange={(value) => handleDataChange("plannedCompletion", value)}
							date={project.plannedCompletion}
						/>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={handleSubmit}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)

}
export default ProjectCreatePage;