import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
} from '@mui/material';
import {
	LOIContext,
} from '../../../contexts';
import { isEmpty } from '../../../helpers/GeneralHelpers';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField,
} from '../../../components/ui/';
import {
	MultiListDragDrop
} from '../../../components/ui/MultiListDragDrop';

const formValidators = {
	name: [Validation.validateNotEmpty, Validation.validateMaxLength(50)]
}

const initialValue = {
	name: "",
	loiName: "",
	hide: false,
	scope: "Assets",
	structuralType: "Assets",
	contextGroupId: null,
	contextAttributes: [],
	dataAttributes: [],
	guidance: "",
	sortOrder: 1,
	isCore: false
}


const AttributeGroupModal = ({ open, onClose, currentGroup }) => {
	const {
		loiAttributesByLoiNameActive,
		updateAttributeGroup,
		loiAttributeGroupsByLoiName,
	} = useContext(LOIContext);
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	const [editedGroup, setEditedGroup] = useState({ ...initialValue, ...currentGroup });
	// Initialize attributes state as a dictionary with two categories
	const [attributes, setAttributes] = useState({
		"Grouped Attributes": [],
		"Ungrouped Attributes": []
	});

	const calculateTakenAttrIdsByLoiName = useCallback((loiName) => {
		const existingAttrGroups = loiAttributeGroupsByLoiName[loiName] || [];
		if (!existingAttrGroups)
			return;

		let allAttrIds = new Set();

		existingAttrGroups.forEach(group => {
			const attributes = group.contextGroupId === null
				? group.contextAttributes?.map(attr => attr.attributeId) || []
				: group.dataAttributes?.map(attr => attr.attributeId) || [];

			// Add to the set
			attributes.forEach(attrId => allAttrIds.add(attrId));
		});
		return allAttrIds;
	}, [loiAttributeGroupsByLoiName])

	useEffect(() => {
		setEditedGroup({ ...initialValue, ...currentGroup });
		resetFormErrors();
	}, [currentGroup, resetFormErrors]);

	useEffect(() => {
		const allAttributes = (loiAttributesByLoiNameActive[currentGroup.loiName] || []).filter(
			attribute => attribute.structuralName === 'Type' || attribute.structuralName === 'Component'
		);
		if (allAttributes.length === 0)
			return;

		if (isEmpty(currentGroup))
			return;

		const useContextAttrs = currentGroup.contextGroupId === null;

		let attrIds = null;
		if (useContextAttrs) {
			attrIds = new Set(currentGroup.contextAttributes?.map(attr => attr.attributeId) || []);
		}
		else {
			attrIds = new Set(currentGroup.dataAttributes?.map(attr => attr.attributeId) || []);
		}

		const takenAttrIds = calculateTakenAttrIdsByLoiName(currentGroup.loiName);

		setAttributes({
			"Grouped Attributes": allAttributes.filter(attr => attrIds.has(attr.id)),
			"Ungrouped Attributes": allAttributes.filter(attr => !attrIds.has(attr.id) && !takenAttrIds.has(attr.id))
		});
	}, [currentGroup, loiAttributesByLoiNameActive, calculateTakenAttrIdsByLoiName]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedGroup({ ...editedGroup, [propertyName]: newValue });
	};

	const handleSave = () => {
		if (isEmpty(currentGroup))
			return false;
		if (!isFormValid(editedGroup)) return;

		const useContextAttrs = currentGroup.contextGroupId === null;

		const updatedGroupedAttributes = attributes["Grouped Attributes"].map(item => ({
			attributeId: item.id,
			name: null,
			forceReadonly: false
		}));
		let newGroup = { ...editedGroup };
		if (useContextAttrs) {
			newGroup.contextAttributes = updatedGroupedAttributes;
		}
		else {
			newGroup.dataAttributes = updatedGroupedAttributes;
		}

		onClose();
		updateAttributeGroup(newGroup);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h4">
				Attribute Group Edit
			</DialogTitle>
			<DialogContent>
				<Stack spacing={1} mt={1}>
					<BasicTxtField
						id="name"
						value={editedGroup?.name || ''}
						onChange={handleDataChange}
						errors={formErrors['name']}
						fullWidth
					/>
					<MultiListDragDrop itemLists={attributes} setItemLists={setAttributes} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog >
	);
};


export default AttributeGroupModal;