const requestQueue = [];
let activeRequests = 0;

/**
 * Initializes a semaphore with a given rate limit.
 * @param {number} maxRequestsPerMinute - Maximum number of requests allowed per minute.
 * @returns {function} - A function that enqueues requests and throttles execution.
 */
export function createSemaphore(maxRequestsPerMinute) {
	const interval = 60000 / maxRequestsPerMinute; // Time between requests in milliseconds
	const requestQueue = [];
	let lastRequestTime = 0;

	function processQueue() {
		if (requestQueue.length > 0) {
			const now = Date.now();
			const timeSinceLastRequest = now - lastRequestTime;

			if (timeSinceLastRequest >= interval) {
				const { callback, resolve, reject } = requestQueue.shift();
				lastRequestTime = now; // Update the time of the last request

				callback()
					.then(resolve)
					.catch(reject)
					.finally(() => {
						// Continue processing the queue
						processQueue();
					});
			} else {
				// Wait until the interval has passed before processing the next request
				setTimeout(processQueue, interval - timeSinceLastRequest);
			}
		}
	}

	return function enqueue(callback) {
		return new Promise((resolve, reject) => {
			requestQueue.push({ callback, resolve, reject });
			processQueue(); // Attempt to process the queue
		});
	};
}