import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';
import { FormControl, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DATE_FORMAT } from '../../constants/Constants';
import { InputError } from './';

// Springboard Date Picker
const SBDatePicker = ({
	id,
	date,
	label,
	onDateChange,
	helperText = null,
	errors = [],
	dateFormat = DATE_FORMAT,
	displayDateFormat = DATE_FORMAT
}) => {
	moment.locale('en-gb');

	// Convert the incoming date from dateFormat to moment object
	const validDate = date ? moment(date, dateFormat) : null;
	const hasError = errors?.length > 0;

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} locale="en-GB">
			<DatePicker
				sx={{
					'.MuiInputBase-input': { padding: '8.5px 14px' },
					'.MuiFormLabel-root': { transform: 'translate(14px, 10px) scale(1)' },
					'.MuiInputLabel-shrink': { transform: 'translate(14px, -9px) scale(0.75)' },
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: hasError ? 'error.main' : 'default',
					},
				}}
				label={label}
				value={validDate}
				onChange={(newDate) => {
					// Format the date for the output when it changes
					console.log("NEW DATE:", newDate);
					const formattedDate = newDate ? moment(newDate).utcOffset(0, true).format(dateFormat) : null;
					console.log("DATE FORMATTED:", formattedDate);
					onDateChange(formattedDate, id);
				}}
				inputFormat={displayDateFormat}
			/>
			{helperText &&
				<FormHelperText style={{ margin: '0 14px' }}>
					{helperText}
				</FormHelperText>
			}
			<InputError field={label} errors={errors} />
		</LocalizationProvider>
	);
};

SBDatePicker.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	date: PropTypes.string.isRequired,
	helperText: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.string),
	onDateChange: PropTypes.func.isRequired,
	dateFormat: PropTypes.string,
	displayDateFormat: PropTypes.string
};

SBDatePicker.defaultProps = {
	label: "Choose Date",
	dateFormat: DATE_FORMAT,
	displayDateFormat: DATE_FORMAT
};

export default SBDatePicker;