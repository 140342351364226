import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Typography,
	Stack
} from '@mui/material';
import {
	ApiStatusDisplay,
	SecurityButton
} from '../../ui';
import { ProjectContext } from '../../../contexts';
import { LoadingPage } from '../';
import ProjectsTableComponent from './ProjectsTableComponent';
import ProjectEditPage from './ProjectEditPage';
import ProjectCreatePage from './ProjectCreatePage';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const ProjectsPage = () => {
	const [busy, setBusy] = useState(false);
	const [edit, setEdit] = useState(false);
	const [create, setCreate] = useState(false);
	const [projectId, setProjectId] = useState(null);

	const { projects, dataLoaded } = useContext(ProjectContext);

	const handleEditProject = (projId) => {
		setProjectId(projId);
		setEdit(true);
	}
	/*
	const getApis = useCallback(async () => {
		setBusy(true);

		const responses = await Promise.all([
			loadProjectsBySite()
		]);
		const projectsData = await responses[0];
		setProjects(projectsData);

		setBusy(false);
	}, [loadProjectsBySite])

	useEffect(() => { getApis() }, [getApis]);
*/
	if (!dataLoaded) {
		return <LoadingPage />;
	}

	return (
		<Box>
			<Stack spacing={2}>
				<ApiStatusDisplay />
				<Typography variant="h4">Project management</Typography>
				{busy && <CircularProgress />}
				{!busy && !edit && !create &&
					<>
						<ProjectsTableComponent
							projects={projects}
							onEdit={handleEditProject}
						/>
						<Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
							<SecurityButton
								featureName={SECURITY_FEATURES.PROJECT_PAGE_CREATE}
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setCreate(true)}>
								Create
							</SecurityButton>
						</Stack>
					</>
				}
				{edit &&
					<ProjectEditPage onClose={() => setEdit(false)} projectId={projectId} />
				}
				{create &&
					<ProjectCreatePage onClose={() => setCreate(false)} />
				}
			</Stack>
		</Box>
	)
}
export default ProjectsPage;