import React, { useState, useContext } from 'react';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import { ProjectContext } from '../../../contexts';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'Projects-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const ProjectsTableComponent = ({ projects, onEdit }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [deleteProject, setDeleteProject] = useState({ id: '', name: '' });

	const { deleteProjectById } = useContext(ProjectContext);

	const handleOpenDeleteDialog = (project) => {
  	setOpenDeleteConfirm(true);
		setDeleteProject({ id: project.id, name: project.name });
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
		setDeleteProject({ id: '', name: '' });
	};

	const handleDelete = async () => {
		await deleteProjectById(deleteProject.id);
		setOpenDeleteConfirm(false);
	}

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'reference',
			headerName: 'Reference',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'clientReference',
			headerName: 'Client reference',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'loiName',
			headerName: 'Level of information name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'documentCount',
			headerName: 'Documents',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'closed',
			headerName: 'Closed',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isHosted',
			headerName: 'Hosted',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isDeleted',
			headerName: 'Deleted',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isHandedOver',
			headerName: 'Handed over',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<SecurityButton
						featureName={SECURITY_FEATURES.PROJECT_PAGE_EDIT}
						color="primary"
						onClick={() => { onEdit(params.row.id) }}>
						Edit
					</SecurityButton>
					<SecurityButton
						featureName={SECURITY_FEATURES.PROJECT_PAGE_DELETE}
						color="primary"
						onClick={() => { handleOpenDeleteDialog(params.row) }}>
						Delete
					</SecurityButton>
				</ButtonGroup>
			)
		},
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={projects}
					columns={columns}
					rowHeight={42}
					autoHeight
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			</Box>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete Project"
				message={`Please confirm that you would like to delete ${deleteProject.name}`}
				onConfirm={handleDelete}
			/>
		</>
	)
}
export default ProjectsTableComponent;