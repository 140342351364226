import React from 'react';
import apiRequestManager from '../api/Services/ApiRequestManager';
import * as Api from '../api/AdminApi';
import * as SpringDB from '../helpers/SpringBoardDBHelpers';
import * as DataAdapter from '../providers/DataAdapters/LOIDataAdapters';
import { UserContext } from './UserContext';
import { hasDataLoaded } from '../helpers/GeneralHelpers';

export const LOIContext = React.createContext(null);

export class LOIProvider extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		this.state = {
			loiNames: [],
			loiNamesSelectList: [],
			loiNamesSiteSelectList: [],
			loiByName: {},
			loiAttributesByLoiName: {},
			loiAttributesByLoiNameActive: {}, // Contains only ID/Name linked to LoiName only
			loiClassificationsByProjectId: {},
			loiDataConfigByName: {},
			loiImportOptionsByProjectId: {},
			loiAttributeGroupsByLoiName: {},
		};
	}

	// #####################
	// LOI PAGE
	// #####################

	loadLOINames = async () => {
		if (hasDataLoaded(this.state.loiNames)) {
			console.log("stateLoi", this.state.loiNames)
			return this.state.loiNames;
		}
		try {
			console.log("LOI names load");
			const loiNames = await Api.loadLOINames();
			this.setState({
				loiNamesSelectList: DataAdapter.convertLOINamesToSelectList(loiNames),
				loiNamesSiteSelectList: DataAdapter.convertLOINamesToSITESelectList(loiNames),
				loiNames: loiNames
			});
			return loiNames;
		} catch (error) {
			console.error("Error loading LOI names:", error);
			throw error;
		}
	}

	loadLOINamesForSite = async (siteId) => {
		try {
			console.log("LOI site names load");
			const loiNames = await Api.loadLOINames(siteId);
			return DataAdapter.convertLOINamesToSelectList(loiNames);
		} catch (error) {
			console.error("Error loading LOI names:", error);
			throw error;
		}
	}

	loadLOIData = async (loiName) => {
		console.log("LOI:", this.state.loiByName[loiName])
		if (hasDataLoaded(this.state.loiByName[loiName]))
			return true;
		try {
			console.log("LOI data load");
			const projectLOI = await Api.loadLOIData(loiName);
			//console.log(docSection);
			this.setState(prevState => ({
				loiByName: {
					...prevState.loiByName,
					[loiName]: DataAdapter.normaliseLOIData(projectLOI)
				}
			}));
			return true;
		} catch (error) {
			console.error("Error loading LOI data:", error);
			//throw error;
		}
	}

	loadLOIAttributes = async () => {
		let requestKey = "loadLOIAttributes";
		let dataLoading = apiRequestManager.isRequestLoading(requestKey);
		if (dataLoading || hasDataLoaded(this.state.loiAttributesByLoiName))
			return;
		try {
			apiRequestManager.setLoadingStatus(requestKey, true);
			console.log("LOI attrs load");
			const projectLOIAttrs = await Api.loadLOIAttributes();
			this.setState({
				loiAttributesByLoiNameActive: DataAdapter.convertLOIAttributesToDictActiveOnly(projectLOIAttrs),
				loiAttributesByLoiName: DataAdapter.convertLOIAttributesToDict(projectLOIAttrs)
			});

			apiRequestManager.setLoadingStatus(requestKey, false);
			return projectLOIAttrs;
		} catch (error) {
			apiRequestManager.setLoadingStatus(requestKey, false);
			console.error("Error loading LOI attribute data:", error);
			throw error;
		}
	}

	loadLOIClassifications = async () => {
		let requestKey = "loadLOIClassifications";
		let dataLoading = apiRequestManager.isRequestLoading(requestKey);
		if (dataLoading || hasDataLoaded(this.state.loiClassificationsByProjectId))
			return;
		try {
			apiRequestManager.setLoadingStatus(requestKey, true);
			console.log("LOI classifications load");
			const projectLOIClassifications = await Api.loadLOIClassifications();
			this.setState({ loiClassificationsByProjectId: DataAdapter.convertLOIClassificationsToDict(projectLOIClassifications) });

			apiRequestManager.setLoadingStatus(requestKey, false);
			return projectLOIClassifications;
		} catch (error) {
			apiRequestManager.setLoadingStatus(requestKey, false);
			console.error("Error loading LOI classification data:", error);
			throw error;
		}
	}

	loadAllLOIData = async (projectId) => {
		try {
			const promises = [
				this.loadLOIData(projectId),
				this.loadLOIAttributes(),
				this.loadLOIClassifications()
			];

			// Use Promise.all to wait for all promises to resolve
			await Promise.all(promises);
			return true;
		} catch (error) {
			// Handle any errors that occur during the async operations
			console.error("An error occurred in loadMainLOIData:", error);
			return false;
		}
	}

	loadLOITableSchema = async () => {
		try {
			await Promise.all([
				this.loadLOIAttributes(),
				this.loadLOIClassifications()
			]);
		} catch (error) {
			// Handle any errors that occur during the async operations
			console.error("An error occurred whilst loading LOI Data:", error);
			return false;
		}
	}

	loadLoiMaskedDataConfig = async (selectedLoi) => {
		console.log("MASKED DATA CONFIG: ", this.state.loiDataConfigByName[selectedLoi])
		if (hasDataLoaded(this.state.loiDataConfigByName[selectedLoi]))
			return this.state.loiDataConfigByName[selectedLoi];
		try {
			const maskedData = await Api.getLoiMaskedDataConfig(selectedLoi);
			this.setState(prevState => ({
				loiDataConfigByName: {
					...prevState.loiDataConfigByName,
					[selectedLoi]: DataAdapter.normaliseLOIMaskedDataConfig(maskedData)
				}
			}));
			return maskedData;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	loadLoiImportOptions = async (projectId) => {
		console.log("Loading LOI Import options for: ", projectId);
		try {
			const importOptions = await Api.loadLoiImportOptions(projectId);
			// Extract all usersIds from files
			const userIds = importOptions.files.reduce((acc, file) => {
				return acc.concat(file.uploadedBy);
			}, []);
			await this.context.loadUsersById(userIds);
			this.setState(prevState => ({
				loiImportOptionsByProjectId: {
					...prevState.loiImportOptionsByProjectId,
					[projectId]: importOptions
				}
			}));
			return importOptions;
		} catch (error) {
			console.error("ERROR:", error);
		}
	}

	updateLOIData = async (loiCellData, selectedLOI) => {
		if (loiCellData.length === 0)
			return;
		let requestId = apiRequestManager.addRequestToQueue("updateLOIData");
		try {
			const loiDataServerFormat = DataAdapter.convertLOIToServerFormat(loiCellData);
			const progressCallback = (progressInfo) => {
				apiRequestManager.updateProgressInQueue(requestId, progressInfo);
			}
			let loiCellUpdates = await Api.updateLOIData(loiDataServerFormat, selectedLOI, progressCallback);
			let loiData = this.state.loiByName[selectedLOI];
			console.log("CELL DATA TEST:", loiCellUpdates);
			// Detect changes somehow? if no changes ignore?
			let updatedLOIData = SpringDB.updateLoiData(loiCellUpdates, loiData);
			console.log("UPDATED LOI DATA:", loiData);
			//await new Promise((resolve) => setTimeout(resolve, 3000));
			this.setState(prevState => ({
				loiByName: {
					...prevState.loiByName,
					[selectedLOI]: updatedLOIData
				}
			}));
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated LOI data");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	updateAttrConfigData = async (attrData, selectedLOI) => {
		let requestId = apiRequestManager.addRequestToQueue("updateAttrConfigData");
		try {
			// TODO: ensure my data matches server data!
			//const loiDataServerFormat = DataAdapter.convertLOIToServerFormat(attrData);
			await Api.updateAttrConfigData(attrData, selectedLOI);
			let loiAttributes = this.state.loiAttributesByLoiName[selectedLOI];
			console.log("ATTR DATA TEST:", attrData);
			console.log("Attributes:", loiAttributes);
			// Detect changes somehow? if no changes ignore?
			let updatedAttrData = SpringDB.updateAttributeInList(attrData, loiAttributes);
			this.setState(prevState => ({
				loiAttributesByLoiName: {
					...prevState.loiAttributesByLoiName,
					[selectedLOI]: updatedAttrData
				}
			}));
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated attribute data");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	updateAttrOrder = async (columnData, selectedLOI) => {
		let requestId = apiRequestManager.addRequestToQueue("updateAttrOrder");
		try {
			await Api.updateAttrOrder(columnData, selectedLOI);
			//let loiAttributes = this.state.loiAttributesByLoiName[selectedLOI];

			// Detect changes somehow? if no changes ignore?
			// TODO: Update order of data here...
			/*let updatedAttrData = SpringDB.updateAttributeInList(attrData, loiAttributes);
			this.setState(prevState => ({
				loiAttributesByLoiName: {
					...prevState.loiAttributesByLoiName,
					[selectedLOI]: updatedAttrData
				}
			}));*/
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated attribute order");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	importLOI = async (importLOIdata) => {
		let requestId = apiRequestManager.addRequestToQueue("importLOI");
		try {
			// TODO: ensure my data matches server data!
			let response = await Api.importLOI(importLOIdata);
			if (!response.queueId) {
				throw new Error("An error occured whilst adding the import operation to the queue");
			}
			apiRequestManager.setRequestSuccess(requestId, "Your import has been added to the queue, we will email you when ready");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	downloadLOI = async (loiName) => {
		let requestId = apiRequestManager.addRequestToQueue("downloadLOI");
		try {
			// TODO: ensure my data matches server data!
			let response = await Api.downloadLOI(loiName);
			if (!response.queueId) {
				throw new Error("An error occured whilst adding the download operation to the queue");
			}
			apiRequestManager.setRequestSuccess(requestId, "Your download has been added to the queue, we will email you when ready");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	deleteLOI = async (loiName) => {
		let requestId = apiRequestManager.addRequestToQueue("deleteLOI");
		try {
			// If no error, then assume success
			await Api.deleteLOI(loiName);
			apiRequestManager.setRequestSuccess(requestId, `LOI: ${loiName} has been successfully deleted!`);
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	copyLOI = async (copyLoiData) => {
		let requestId = apiRequestManager.addRequestToQueue("copyLOI");
		try {
			// TODO: ensure my data matches server data!
			let response = await Api.copyLOI(copyLoiData);
			if (!response.queueId) {
				throw new Error("An error occured whilst adding the copy operation to the queue");
			}
			apiRequestManager.setRequestSuccess(requestId, "Your copy operation has been added to the queue, we will email you when ready");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	updateScheduleToMatchLOI = async (loiName) => {
		let requestId = apiRequestManager.addRequestToQueue("updateScheduleToMatchLOI");
		try {
			// TODO: ensure my data matches server data!
			let response = await Api.updateScheduleToMatchLOI(loiName);
			if (!response.queueId) {
				throw new Error("An error occured whilst adding the update schedule operation to the queue");
			}
			apiRequestManager.setRequestSuccess(requestId, "Your update schedule operation has been added to the queue, we will email you when ready");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	loadAttributeGroupsByLOIName = async (loiName) => {
		console.log("LOI:", this.state.loiAttributeGroupsByLoiName[loiName])
		if (hasDataLoaded(this.state.loiAttributeGroupsByLoiName[loiName]))
			return true;
		try {
			console.log("LOI attribute groups data load");
			const attrGroupsData = await Api.loadAttributeGroupsByLOIName(loiName);
			this.setState(prevState => ({
				loiAttributeGroupsByLoiName: {
					...prevState.loiAttributeGroupsByLoiName,
					[loiName]: attrGroupsData
				}
			}));
			return true;
		} catch (error) {
			console.error("Error loading attribute groups LOI data:", error);
		}
	}

	updateAttributeGroup = async (attrGroup) => {
		let requestId = apiRequestManager.addRequestToQueue("updateAttributeGroup");
		try {
			let response = await Api.updateAttributeGroup(attrGroup);
			this.setState(prevState => {
				// Retrieve the array for the specified loiName
				const existingAttrGroups = prevState.loiAttributeGroupsByLoiName[attrGroup.loiName] || [];

				// Map through the array to update the specific attrGroup by id
				const updatedAttrGroups = existingAttrGroups.map(group =>
					group.id === attrGroup.id ? { ...group, ...attrGroup } : group
				);

				return {
					loiAttributeGroupsByLoiName: {
						...prevState.loiAttributeGroupsByLoiName,
						[attrGroup.loiName]: updatedAttrGroups
					}
				};
			});
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated attribute group!");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	createAttributeGroup = async (name, loiName) => {
		let requestId = apiRequestManager.addRequestToQueue("createAttributeGroup");
		try {
			let newAttrGroup = await Api.createAttributeGroup(name, loiName);
			this.setState(prevState => {
				const existingAttrGroups = prevState.loiAttributeGroupsByLoiName[loiName] || [];

				return {
					loiAttributeGroupsByLoiName: {
						...prevState.loiAttributeGroupsByLoiName,
						[loiName]: [...existingAttrGroups, newAttrGroup]
					}
				};
			});
			apiRequestManager.setRequestSuccess(requestId, "Successfully created attribute group!");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	deleteAttributeGroup = async (id, loiName) => {
		let requestId = apiRequestManager.addRequestToQueue("deleteAttributeGroup");
		try {
			let deleteResult = await Api.deleteAttributeGroup(id, loiName);
			this.setState(prevState => {
				const existingAttrGroups = prevState.loiAttributeGroupsByLoiName[loiName] || [];
				const updatedAttrGroups = existingAttrGroups.filter(attrGroup => attrGroup.id !== id);

				return {
					loiAttributeGroupsByLoiName: {
						...prevState.loiAttributeGroupsByLoiName,
						[loiName]: updatedAttrGroups
					}
				};
			});
			apiRequestManager.setRequestSuccess(requestId, "Successfully deleted attribute group!");
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	};

	render() {
		const loiContextValue = {
			loiNames: this.state.loiNames,
			loiNamesSelectList: this.state.loiNamesSelectList,
			loiNamesSiteSelectList: this.state.loiNamesSiteSelectList,
			loiByName: this.state.loiByName,
			loiAttributesByLoiName: this.state.loiAttributesByLoiName,
			loiAttributesByLoiNameActive: this.state.loiAttributesByLoiNameActive,
			loiClassificationsByProjectId: this.state.loiClassificationsByProjectId,
			loiDataConfigByName: this.state.loiDataConfigByName,
			loiImportOptionsByProjectId: this.state.loiImportOptionsByProjectId,
			loiAttributeGroupsByLoiName: this.state.loiAttributeGroupsByLoiName,
			loadLOITableSchema: this.loadLOITableSchema,
			loadLOIAttributes: this.loadLOIAttributes,
			loadLOIData: this.loadLOIData,
			loadAllLOIData: this.loadAllLOIData,
			loadLOINames: this.loadLOINames,
			loadLOINamesForSite: this.loadLOINamesForSite,
			loadLoiMaskedDataConfig: this.loadLoiMaskedDataConfig,
			loadLoiImportOptions: this.loadLoiImportOptions,
			updateLOIData: this.updateLOIData,
			updateAttrConfigData: this.updateAttrConfigData,
			updateAttrOrder: this.updateAttrOrder,
			updateScheduleToMatchLOI: this.updateScheduleToMatchLOI,
			importLOI: this.importLOI,
			downloadLOI: this.downloadLOI,
			deleteLOI: this.deleteLOI,
			copyLOI: this.copyLOI,
			loadAttributeGroupsByLOIName: this.loadAttributeGroupsByLOIName,
			updateAttributeGroup: this.updateAttributeGroup,
			createAttributeGroup: this.createAttributeGroup,
			deleteAttributeGroup: this.deleteAttributeGroup
		}

		return (
			<LOIContext.Provider value={loiContextValue}>
				{this.props.children}
			</LOIContext.Provider>
		);
	}
}

export default LOIProvider;