import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { RequestSnackbar } from './';
import apiRequestManager from '../../api/Services/ApiRequestManager';
import TimeDisplay from './TimeDisplay';

const DisplayLoadingPercent = ({ percent }) => {
	if (percent === 0)
		return null;

	// Multiply by 100, floor it, then divide by 100 to round down to two decimal places
	const roundedPercent = Math.floor(percent * 100) / 100;

	return (
		<>
			{roundedPercent}%
		</>
	);
}

const ApiStatusDisplay = () => {
	const [requestQueue, setRequestQueue] = useState(apiRequestManager.requestQueue);

	useEffect(() => {
		const unsubscribe = apiRequestManager.subscribe(setRequestQueue);

		// Cleanup on unmount
		return () => unsubscribe();
	}, []);

	let loadingRequests = 0;
	const totalLoadingProgress = requestQueue.reduce(
		(acc, request) => {
			if (request.loading) {
				loadingRequests++;
				acc.sumOfLoadingProgress += request.progress;
				acc.sumOfRemainingTime += request.estimatedRemainingTime;
				console.log("ESTIMATED REMAINING:", request.estimatedRemainingTime);
			}
			return acc;
		},
		{ sumOfLoadingProgress: 0, sumOfRemainingTime: 0 }
	);

	const avgLoadingProgress = loadingRequests > 0
		? totalLoadingProgress.sumOfLoadingProgress / loadingRequests
		: 0;

	const overallRemainingTime = totalLoadingProgress.sumOfRemainingTime;

	const isLoading = requestQueue.some(request => request.loading);
	const nonLoadingRequest = requestQueue.find(request => !request.loading);

	console.log("Loading req:", isLoading);
	console.log("REQ QUEUE:", requestQueue);

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
				<Typography variant="h6" sx={{ marginLeft: 2 }}>
					<div>
						Loading... <DisplayLoadingPercent percent={avgLoadingProgress} />
					</div>
					<div>
						Estimated Remaining Time: <TimeDisplay seconds={overallRemainingTime} />
					</div>
				</Typography>
			</Backdrop>
			{nonLoadingRequest && <RequestSnackbar request={nonLoadingRequest} />}
		</div>
	);
};

export default ApiStatusDisplay;