import React from 'react';

const TimeDisplay = ({ seconds }) => {
	const formatTime = (seconds) => {
		if (seconds <= 0) return "0 seconds";

		const hrs = Math.floor(seconds / 3600);
		const mins = Math.floor((seconds % 3600) / 60);
		const secs = Math.floor(seconds % 60);

		const parts = [];
		if (hrs > 0) parts.push(`${hrs} hour${hrs > 1 ? 's' : ''}`);
		if (mins > 0) parts.push(`${mins} minute${mins > 1 ? 's' : ''}`);
		if (secs > 0 || parts.length === 0) parts.push(`${secs} second${secs > 1 ? 's' : ''}`);

		return parts.join(', ');
	};

	return <span>{formatTime(seconds)}</span>;
};

export default TimeDisplay;