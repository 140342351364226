import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormHelperText,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {
	InputError,
	SBDatePicker,
	SBCheckBoxField,
	SelectDropdown,
	BasicTxtField,
} from '../../../components/ui/';
import { HelpContext, LOIContext, ProjectContext, SiteDataContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { convertArrayToSelectList, isObjectEmpty } from '../../../helpers/GeneralHelpers';
import { SERVER_DATE_FORMAT } from '../../../constants/Constants';

const formValidators = {
	name: [Validation.validateNotEmpty],
	startDate: [Validation.validateNotEmpty],
	loiName: [Validation.validateNotEmpty]
}

const styles = {
	formHelperText: { margin: '0 14px' }
};

const projectEditHelpTitles = [
	'projectClosed',
	'projectHosted',
	'projectHostedEndDate',
	'projectDeleted',
	'projectStartDate',
	'PCDate',
	'siteLOIName',
	'filterdLOIList',
	'fileDetailLevel',
	'fileTypeGroup',
	'taskProcess',
	'maintenanceProcess',
	'RIBAStage',
	'facility',
	'redirectReviewTasks',
	'projectHandOverDate',
];

const initialValue = {
	name: '',
	reference: '',
	clientReference: '',
	tags: [],
	weightedValue: 0,
	isClosed: false,
	isHosted: false,
	dateHosted: '',
	isDeleted: false,
	startDate: '',
	plannedCompletion: '',
	loiName: '',
	hideUnusedClassifications: false,
	fileDetailLevel: 0,
	linkTypeSet: '',
	taskVersion: 0,
	systemMaintenanceVersion: 0,
	currentRIBAstage: 0,
	facilityId: '',
	redirectInReviewTasks: false,
	handOverDate: '',
};

const ProjectEditPage = ({ onClose, projectId }) => {
	const [busy, setBusy] = useState(false);
	const [project, setProject] = useState({ ...initialValue });
	const [formErrors, setFormErrors] = useState({});

	const { helpTexts, loadHelpByTitles } = useContext(HelpContext);
	const { loadLOINames, loiNamesSelectList } = useContext(LOIContext);
	const { loadProjectById, updateProject } = useContext(ProjectContext);
	const {
		loadFileDetailLevels,
		flatFileDetailLevels,
		loadLinkTypes,
		linkTypes,
		loadTaskVersions,
		taskVersions,
		loadSystemMaintenanceVersions,
		systemMaintenanceVersions,
		loadRIBAStages,
		RIBAWorkStages,
		loadSiteLocations,
		facilityList,
	} = useContext(SiteDataContext);

	// useEffect(() => {
	// 	console.log('edited project', project);
	// }, [project])

	useEffect(() => {
		setBusy(true);

		async function getApis() {
			const responses = await Promise.all([
				loadProjectById(projectId),
				loadHelpByTitles(projectEditHelpTitles),
				loadLOINames(),
				loadFileDetailLevels(),
				loadLinkTypes(),
				loadTaskVersions(),
				loadSystemMaintenanceVersions(),
				loadRIBAStages(),
				loadSiteLocations(),
			]);
			const projectData = await responses[0];

			setProject(projectData);
			setBusy(false);
		}

		getApis();
	}, [
		loadProjectById,
		projectId,
		loadHelpByTitles,
		loadLOINames,
		loadFileDetailLevels,
		loadLinkTypes,
		loadTaskVersions,
		loadSystemMaintenanceVersions,
		loadRIBAStages,
		loadSiteLocations,
	]);

	const linkTypesSelectList = useMemo(() => convertArrayToSelectList(linkTypes), [linkTypes]);

	const handleDataChange = (propertyName, newValue) => {
		setProject(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		const projectData = await updateProject(project);
		if (projectData) {
			onClose();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(project.name, "name", formValidators, newFormErrors);
		Validation.setFieldErrors(project.startDate || '', "startDate", formValidators, newFormErrors);
		Validation.setFieldErrors(project.loiName || '', "loiName", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	if (busy) {
		return (
			<Box>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box className="form-container">
			<Stack sx={{ mb: 3, }} direction='column' spacing={3}>
				<Stack direction='row' justifyContent="space-between" alignItems="flex-end">
					<Typography variant="body1">Edit project</Typography>
					<Typography variant="body2">* Required field</Typography>
				</Stack>
				<BasicTxtField
					id={"name"}
					label="Name"
					value={project.name}
					onChange={(value) => handleDataChange("name", value)}
					fullWidth={true}
					errors={formErrors['name']}
					required
				/>
				<BasicTxtField
					id={"reference"}
					label="Reference"
					value={project.reference}
					onChange={(value) => handleDataChange("reference", value)}
					fullWidth={true}
				/>
				<BasicTxtField
					id={"clientReference"}
					label="Client reference"
					value={project.clientReference}
					onChange={(value) => handleDataChange("clientReference", value)}
					fullWidth={true}
				/>
				<TextField
					id={"apiTags"}
					label="API tags"
					value={project.tags.join('\n')}
					onChange={(value) => handleDataChange("tags", value.target.value.split('\n'))}
					multiline
					rows={4}
					helperText="One per line"
				/>
				<BasicTxtField
					id={"productWeighting"}
					label="Product weighting"
					value={project.weightedValue}
					onChange={(value) => handleDataChange("weightedValue", value)}
					fullWidth={true}
					type="number"
				/>
				<Divider />
				<Typography variant="body1">Status</Typography>
				<SBCheckBoxField
					id="closed"
					label="Closed"
					checked={project.isClosed}
					onChange={(value) => handleDataChange("isClosed", value)}
					helperText={helpTexts["projectClosed"]}
				/>
				<SBCheckBoxField
					id="hosted"
					label="Hosted"
					checked={project.isHosted}
					onChange={(value) => handleDataChange("isHosted", value)}
					helperText={helpTexts["projectHosted"]}
				/>
				<SBDatePicker
					id="endOfHosting"
					label="End of hosting"
					helperText={helpTexts["projectHostedEndDate"]}
					dateFormat={SERVER_DATE_FORMAT}
					onDateChange={(value) => handleDataChange("dateHosted", value)}
					date={project.dateHosted}
				/>
				<SBCheckBoxField
					id="deleted"
					label="Deleted"
					checked={project.isDeleted}
					onChange={(value) => handleDataChange("isDeleted", value)}
					helperText={helpTexts["projectDeleted"]}
				/>
				<Divider />
				<Typography variant="body1">Dates</Typography>
				<SBDatePicker
					id="startDate"
					label="Start date"
					helperText={helpTexts["projectStartDate"]}
					errors={formErrors["startDate"]}
					dateFormat={SERVER_DATE_FORMAT}
					onDateChange={(value) => handleDataChange("startDate", value)}
					date={project.startDate}
				/>
				<SBDatePicker
					id="pcDate"
					label="PC date"
					helperText={helpTexts["PCDate"]}
					dateFormat={SERVER_DATE_FORMAT}
					onDateChange={(value) => handleDataChange("plannedCompletion", value)}
					date={project.plannedCompletion}
				/>
				<Divider />
				<Typography variant="body1">Configuration</Typography>
				<SelectDropdown
					id="levelOfInformationName"
					label="Level of Information Name"
					value={project.loiName}
					helperText={helpTexts["siteLOIName"]}
					errors={formErrors["loiName"]}
					onChange={(value) => handleDataChange("loiName", value)}
					options={loiNamesSelectList}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SBCheckBoxField
					id="filteredLOIList"
					label="Filtered LOI List"
					checked={project.hideUnusedClassifications}
					onChange={(value) => handleDataChange("hideUnusedClassifications", value)}
					helperText={helpTexts["filterdLOIList"]}
				/>
				<SelectDropdown
					id="fileDetailLevel"
					label="File detail level"
					value={project.fileDetailLevel}
					helperText={helpTexts["fileDetailLevel"]}
					onChange={(value) => handleDataChange("fileDetailLevel", value)}
					options={flatFileDetailLevels}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SelectDropdown
					id="fileTypeGroup"
					label="File Type Group"
					value={project.linkTypeSet}
					helperText={helpTexts["fileTypeGroup"]}
					onChange={(value) => handleDataChange("linkTypeSet", value)}
					options={linkTypesSelectList}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SelectDropdown
					id="taskProcess"
					label="Task Process"
					value={project.taskVersion}
					helperText={helpTexts["taskProcess"]}
					onChange={(value) => handleDataChange("taskVersion", value)}
					options={systemMaintenanceVersions}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SelectDropdown
					id="maintenanceProcess"
					label="Maintenance Process"
					value={project.systemMaintenanceVersion}
					helperText={helpTexts["maintenanceProcess"]}
					onChange={(value) => handleDataChange("systemMaintenanceVersion", value)}
					options={taskVersions}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SelectDropdown
					id="validationStage"
					label="Validation Stage"
					value={project.currentRIBAstage}
					helperText={helpTexts["RIBAStage"]}
					onChange={(value) => handleDataChange("currentRIBAstage", value)}
					options={RIBAWorkStages}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SelectDropdown
					id="facility"
					label="Facility"
					value={project.facilityId}
					helperText={helpTexts["facility"]}
					onChange={(value) => handleDataChange("facilityId", value)}
					options={facilityList}
					usePlaceholder={true}
					fullWidth={true}
				/>
				<SBCheckBoxField
					id="redirectReviewTasks"
					label="Redirect review tasks"
					checked={project.redirectInReviewTasks}
					onChange={(value) => handleDataChange("redirectInReviewTasks", value)}
					helperText={helpTexts["redirectReviewTasks"]}
				/>
				<SBDatePicker
					id="informationHandoverDate"
					label="Information handover date"
					helperText={helpTexts["projectHandOverDate"]}
					dateFormat={SERVER_DATE_FORMAT}
					onDateChange={(value) => handleDataChange("handOverDate", value)}
					date={project.handOverDate}
				/>
			</Stack>
			<Stack direction='row' spacing={2} justifyContent="flex-end">
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={handleSubmit}>
					Submit
				</Button>
			</Stack>
		</Box>
	);
}

export default ProjectEditPage;